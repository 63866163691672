<template>
  <div id="map" class="map">
    <div class="map-text">活动阁商家全国分布</div>
  </div>
</template>

<script>
import { getShopLocation } from '@/api/index'
export default {
  mounted() {
    // 更新地图
    this.init()
  },
  data() {
    return {
      zoom: 5
    }
  },
  methods: {
    /**
     * 初始化地图
     */
    async init() {
      var map = new AMap.Map('map', {
        mapStyle: 'amap://styles/grey',
        zoom: this.zoom,
        scrollWheel: false,
        doubleClickZoom: true,
        dragEnable: true,
        center: [107.791168, 35.505949]
      })

      // 拉取服务端数据
      const { data } = await getShopLocation()
      const list = data

      // 渲染地图logo
      list.forEach(item => {
        if (item.shop_cover) {
          // logo宽度
          const width =
            item.total / (list[0].total / 20) > 20
              ? 60
              : 40 + item.total / (list[0].total / 20)
          let marker = null
          // 每个地图标点对象
          if (item.shop_cover) {
            marker = new AMap.Marker({
              content: `<div class="item-logo" style="width: ${width}px; height: ${width}px">
                      <div class="item-proup">${item.shop_name}</div>
                      <img src="${item.shop_cover}">
                  </div>`, // 自定义点标记覆盖物内容
              position: [item.lon, item.lat], // 基点位置
              offset: new AMap.Pixel(0, 0) // 相对于基点的偏移位置
            })
          } else if (item.shop_name) {
            // 生成随机16进制颜色值
            const color =
              '#' +
              Math.random()
                .toString(16)
                .substr(-6)
            // 获取昵称的首字母
            const text = item.shop_name.substr(0, 1)
            // 每个地图标点对象
            marker = new AMap.Marker({
              content: `<div class="item-logo" style="width: ${width}px; height: ${width}px;">
                <div class="item-proup">${item.shop_name}</div>
                <div class="item-name" style="background-color: ${color}">${text}</div>
              </div>`, // 自定义点标记覆盖物内容
              position: [item.lon, item.lat], // 基点位置
              offset: new AMap.Pixel(0, 0) // 相对于基点的偏移位置
            })
          }
          map.add(marker)
        }
      })

      // 显示加减号缩放
      AMap.plugin(['AMap.ToolBar'], function() {
        // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
        map.addControl(
          new AMap.ToolBar({
            // 简易缩放模式，默认为 false
            liteStyle: true
          })
        )
      })
    }
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 800px;
  position: relative;
  .amap-logo {
    display: none !important;
  }
  .map-text {
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 99;
    font-size: 20px;
    color: #ccc;
  }
}
.item-logo {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  border: 4px solid rgba(236, 236, 236, 0.1);
  .item-proup {
    white-space: nowrap;
    background-color: rgba(136, 136, 136, 0.5);
    color: #fff;
    position: absolute;
    z-index: 9;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 2px;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(136, 136, 136, 0.5);
    }
  }
  &:hover .item-proup {
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .item-name {
    color: #fff;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
